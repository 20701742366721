define("discourse/plugins/chat/discourse/lib/hashtag-types/channel", ["exports", "@ember/service", "discourse/lib/hashtag-types/base", "discourse/lib/icon-library"], function (_exports, _service, _base, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChannelHashtagType extends _base.default {
    static #_ = (() => dt7948.g(this.prototype, "chatChannelsManager", [_service.service]))();
    #chatChannelsManager = (() => (dt7948.i(this, "chatChannelsManager"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    get type() {
      return "channel";
    }
    get preloadedData() {
      if (this.currentUser) {
        return this.chatChannelsManager.publicMessageChannels;
      } else {
        return [];
      }
    }
    generateColorCssClasses(channelOrHashtag) {
      const color = channelOrHashtag.colors ? channelOrHashtag.colors[0] : channelOrHashtag.chatable.color;
      return [`.d-icon.hashtag-color--${this.type}-${channelOrHashtag.id} { color: #${color} }`];
    }
    generateIconHTML(hashtag) {
      hashtag.colors ? this.onLoad(hashtag) : this.load(hashtag.id);
      return (0, _iconLibrary.iconHTML)(hashtag.icon, {
        class: `hashtag-color--${this.type}-${hashtag.id}`
      });
    }
    isLoaded(id) {
      return !this.site.lazy_load_categories || super.isLoaded(id);
    }
  }
  _exports.default = ChannelHashtagType;
});