define("discourse/plugins/discourse-ai/discourse/lib/ai-streamer/updaters/stream-updater", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Interface needed to implement for a streaming updater
   */
  class StreamUpdater {
    set streaming(value) {
      throw "not implemented";
    }
    async setCooked() {
      throw "not implemented";
    }
    async setRaw() {
      throw "not implemented";
    }
    get element() {
      throw "not implemented";
    }
    get raw() {
      throw "not implemented";
    }
  }
  _exports.default = StreamUpdater;
});