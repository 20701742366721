define("discourse/plugins/chat/discourse/components/chat/modal/edit-channel-description", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    class="chat-modal-edit-channel-description"
    @inline={{@inline}}
    @title={{i18n "chat.channel_edit_description_modal.title"}}
    @flash={{this.flash}}
  >
    <:body>
      <span class="chat-modal-edit-channel-description__description">{{i18n
          "chat.channel_edit_description_modal.description"
        }}</span>
      <CharCounter
        @value={{this.editedDescription}}
        @max={{this.descriptionMaxLength}}
      >
        <textarea
          {{on "input" (with-event-value this.onChangeChatChannelDescription)}}
          class="chat-modal-edit-channel-description__description-input"
          placeholder={{i18n
            "chat.channel_edit_description_modal.input_placeholder"
          }}
        >{{this.editedDescription}}</textarea>
      </CharCounter>
    </:body>
    <:footer>
      <DButton
        @action={{this.onSaveChatChannelDescription}}
        @label="save"
        @disabled={{this.isSaveDisabled}}
        class="btn-primary create"
      />
      <DModalCancel @close={{@closeModal}} />
    </:footer>
  </DModal>
  */
  {
    "id": "oTCf2Kx8",
    "block": "[[[8,[39,0],[[24,0,\"chat-modal-edit-channel-description\"]],[[\"@closeModal\",\"@inline\",\"@title\",\"@flash\"],[[30,1],[30,2],[28,[37,1],[\"chat.channel_edit_description_modal.title\"],null],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,1],[14,0,\"chat-modal-edit-channel-description__description\"],[12],[1,[28,[35,1],[\"chat.channel_edit_description_modal.description\"],null]],[13],[1,\"\\n    \"],[8,[39,4],null,[[\"@value\",\"@max\"],[[30,0,[\"editedDescription\"]],[30,0,[\"descriptionMaxLength\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[11,\"textarea\"],[24,0,\"chat-modal-edit-channel-description__description-input\"],[16,\"placeholder\",[28,[37,1],[\"chat.channel_edit_description_modal.input_placeholder\"],null]],[4,[38,6],[\"input\",[28,[37,7],[[30,0,[\"onChangeChatChannelDescription\"]]],null]],null],[12],[1,[30,0,[\"editedDescription\"]]],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,9],[[24,0,\"btn-primary create\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"onSaveChatChannelDescription\"]],\"save\",[30,0,[\"isSaveDisabled\"]]]],null],[1,\"\\n    \"],[8,[39,10],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@inline\"],false,[\"d-modal\",\"i18n\",\":body\",\"span\",\"char-counter\",\"textarea\",\"on\",\"with-event-value\",\":footer\",\"d-button\",\"d-modal-cancel\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/chat/modal/edit-channel-description.hbs",
    "isStrictMode": false
  });
  const DESCRIPTION_MAX_LENGTH = 280;
  class ChatModalEditChannelDescription extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "editedDescription", [_tracking.tracked], function () {
      return this.channel.description || "";
    }))();
    #editedDescription = (() => (dt7948.i(this, "editedDescription"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "flash", [_tracking.tracked]))();
    #flash = (() => (dt7948.i(this, "flash"), void 0))();
    get channel() {
      return this.args.model;
    }
    get isSaveDisabled() {
      return this.channel.description === this.editedDescription || this.editedDescription?.length > DESCRIPTION_MAX_LENGTH;
    }
    get descriptionMaxLength() {
      return DESCRIPTION_MAX_LENGTH;
    }
    async onSaveChatChannelDescription() {
      try {
        const result = await this.chatApi.updateChannel(this.channel.id, {
          description: this.editedDescription
        });
        this.channel.description = result.channel.description;
        this.args.closeModal();
      } catch (error) {
        this.flash = (0, _ajaxError.extractError)(error);
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "onSaveChatChannelDescription", [_object.action]))();
    onChangeChatChannelDescription(description) {
      this.flash = null;
      this.editedDescription = description;
    }
    static #_5 = (() => dt7948.n(this.prototype, "onChangeChatChannelDescription", [_object.action]))();
  }
  _exports.default = ChatModalEditChannelDescription;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatModalEditChannelDescription);
});