define("discourse/plugins/discourse-ai/discourse/lib/virtual-element-from-caret-coords", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = virtualElementFromCaretCoords;
  class VirtualElementFromCaretCoords {
    constructor(caretCoords) {
      let offset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [0, 0];
      this.caretCoords = caretCoords;
      this.offset = offset;
      this.updateRect();
    }
    updateRect() {
      const [xOffset, yOffset] = this.offset;
      this.rect = {
        top: this.caretCoords.y + yOffset,
        right: this.caretCoords.x,
        bottom: this.caretCoords.y + yOffset,
        left: this.caretCoords.x + xOffset,
        width: 0,
        height: 0,
        x: this.caretCoords.x,
        y: this.caretCoords.y,
        toJSON() {
          return this;
        }
      };
      return this.rect;
    }
    getBoundingClientRect() {
      return this.rect;
    }
    getClientRects() {
      return [this.rect];
    }
    get clientWidth() {
      return this.rect.width;
    }
    get clientHeight() {
      return this.rect.height;
    }
  }
  function virtualElementFromCaretCoords(caretCoords, offset) {
    return new VirtualElementFromCaretCoords(caretCoords, offset);
  }
});