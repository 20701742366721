define("discourse/plugins/automation/admin/models/discourse-automation-field", ["exports", "@glimmer/tracking", "@ember-compat/tracked-built-ins"], function (_exports, _tracking, _trackedBuiltIns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscourseAutomationField {
    static create(template, target) {
      let json = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const field = new DiscourseAutomationField();
      field.acceptsPlaceholders = template.accepts_placeholders;
      field.acceptedContexts = template.accepted_contexts;
      field.targetName = target.name;
      field.targetType = target.type;
      field.name = template.name;
      field.component = template.component;
      field.isDisabled = template.read_only;

      // backwards compatibility with forced scriptable fields
      if (field.isDisabled) {
        field.metadata.value = template.default_value || template.value || json?.metadata?.value;
      } else {
        field.metadata.value = template.value || json?.metadata?.value || template.default_value;
      }

      // null is not a valid value for metadata.value
      if (field.metadata.value === null) {
        field.metadata.value = undefined;
      }
      field.isRequired = template.is_required;
      field.extra = new _trackedBuiltIns.TrackedObject(template.extra);
      return field;
    }
    static #_ = (() => dt7948.g(this.prototype, "acceptsPlaceholders", [_tracking.tracked], function () {
      return false;
    }))();
    #acceptsPlaceholders = (() => (dt7948.i(this, "acceptsPlaceholders"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "component", [_tracking.tracked]))();
    #component = (() => (dt7948.i(this, "component"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "extra", [_tracking.tracked], function () {
      return new _trackedBuiltIns.TrackedObject();
    }))();
    #extra = (() => (dt7948.i(this, "extra"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "isDisabled", [_tracking.tracked], function () {
      return false;
    }))();
    #isDisabled = (() => (dt7948.i(this, "isDisabled"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "isRequired", [_tracking.tracked], function () {
      return false;
    }))();
    #isRequired = (() => (dt7948.i(this, "isRequired"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "metadata", [_tracking.tracked], function () {
      return new _trackedBuiltIns.TrackedObject();
    }))();
    #metadata = (() => (dt7948.i(this, "metadata"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "name", [_tracking.tracked]))();
    #name = (() => (dt7948.i(this, "name"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "targetType", [_tracking.tracked]))();
    #targetType = (() => (dt7948.i(this, "targetType"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "targetName", [_tracking.tracked]))();
    #targetName = (() => (dt7948.i(this, "targetName"), void 0))();
    toJSON() {
      return {
        name: this.name,
        target: this.targetType,
        component: this.component,
        metadata: this.metadata
      };
    }
  }
  _exports.default = DiscourseAutomationField;
});