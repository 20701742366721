define("discourse/plugins/discourse-ai/discourse/components/post-menu/ai-debug-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/plugins/discourse-ai/discourse/lib/ai-bot-helper", "discourse/plugins/discourse-ai/discourse/components/modal/debug-ai-modal", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _aiBotHelper, _debugAiModal, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiDebugButton extends _component.default {
    static shouldRender(args) {
      return (0, _aiBotHelper.isPostFromAiBot)(args.post, args.state.currentUser);
    }
    // TODO (glimmer-post-menu): Remove this static function and move the code into the button action after the widget code is removed
    static debugAiResponse(post, modal) {
      modal.show(_debugAiModal.default, {
        model: post
      });
    }
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    debugAiResponse() {
      this.constructor.debugAiResponse(this.args.post, this.modal);
    }
    static #_2 = (() => dt7948.n(this.prototype, "debugAiResponse", [_object.action]))();
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="post-action-menu__debug-ai"
          ...attributes
          @action={{this.debugAiResponse}}
          @icon="info"
          @title="discourse_ai.ai_bot.debug_ai"
        />
      
    */
    {
      "id": "a7gp58E/",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"post-action-menu__debug-ai\"],[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"debugAiResponse\"]],\"info\",\"discourse_ai.ai_bot.debug_ai\"]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/post-menu/ai-debug-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiDebugButton;
});