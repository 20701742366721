define("discourse/plugins/discourse-ai/discourse/discourse-ai-shared-conversation-show-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("discourse-ai-shared-conversation-show", {
      path: "/discourse-ai/ai-bot/shared-ai-conversations/:share_key"
    });
  }
});