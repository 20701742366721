define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-tools-edit", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscourseAiToolsEditRoute extends _discourse.default {
    async model(params) {
      const allTools = this.modelFor("adminPlugins.show.discourse-ai-tools");
      const id = parseInt(params.id, 10);
      return allTools.find(tool => tool.id === id);
    }
    setupController(controller) {
      super.setupController(...arguments);
      const toolsModel = this.modelFor("adminPlugins.show.discourse-ai-tools");
      controller.set("allTools", toolsModel);
      controller.set("presets", toolsModel.resultSetMeta.presets);
    }
  }
  _exports.default = DiscourseAiToolsEditRoute;
});