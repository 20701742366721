define("discourse/plugins/discourse-ai/discourse/templates/connectors/after-d-editor/composer-open", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isAiBotChat}}
    {{body-class this.aiBotClasses}}
    {{#if this.renderChatWarning}}
      <div class="ai-bot-chat-warning">{{i18n
          "discourse_ai.ai_bot.pm_warning"
        }}</div>
    {{/if}}
  {{/if}}
  */
  {
    "id": "t7q115/2",
    "block": "[[[41,[30,0,[\"isAiBotChat\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"aiBotClasses\"]]],null]],[1,\"\\n\"],[41,[30,0,[\"renderChatWarning\"]],[[[1,\"    \"],[10,0],[14,0,\"ai-bot-chat-warning\"],[12],[1,[28,[35,3],[\"discourse_ai.ai_bot.pm_warning\"],null]],[13],[1,\"\\n\"]],[]],null]],[]],null]],[],false,[\"if\",\"body-class\",\"div\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/templates/connectors/after-d-editor/composer-open.hbs",
    "isStrictMode": false
  });
});