define("discourse/plugins/discourse-ai/discourse/admin-discourse-ai-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin.dashboard",
    path: "/dashboard",
    map() {
      this.route("admin.dashboardSentiment", {
        path: "/dashboard/sentiment",
        resetNamespace: true
      });
    }
  };
});