define("discourse/plugins/automation/admin/components/fields/da-base-field", ["exports", "@glimmer/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class BaseField extends _component.default {
    get displayPlaceholders() {
      return this.args.placeholders?.length && this.args.field?.acceptsPlaceholders;
    }
    mutValue(newValue) {
      this.args.field.metadata.value = newValue;
    }
    static #_ = (() => dt7948.n(this.prototype, "mutValue", [_object.action]))();
  }
  _exports.default = BaseField;
});