define("discourse/plugins/automation/admin/templates/admin-plugins-discourse-automation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <LinkTo
    @route="adminPlugins.discourse-automation"
    class="discourse-automation-title"
  >
    <h1 class="title">
      {{i18n "discourse_automation.title"}}
    </h1>
  
    {{#if this.showNewAutomation}}
      <DButton
        @label="discourse_automation.create"
        @icon="plus"
        @action={{action "newAutomation"}}
        class="new-automation"
      />
    {{/if}}
  </LinkTo>
  
  <hr />
  
  {{outlet}}
  */
  {
    "id": "RP/6NYE0",
    "block": "[[[8,[39,0],[[24,0,\"discourse-automation-title\"]],[[\"@route\"],[\"adminPlugins.discourse-automation\"]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"h1\"],[14,0,\"title\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"discourse_automation.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showNewAutomation\"]],[[[1,\"    \"],[8,[39,4],[[24,0,\"new-automation\"]],[[\"@label\",\"@icon\",\"@action\"],[\"discourse_automation.create\",\"plus\",[28,[37,5],[[30,0],\"newAutomation\"],null]]],null],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\\n\"],[10,\"hr\"],[12],[13],[1,\"\\n\\n\"],[46,[28,[37,8],null,null],null,null,null]],[],false,[\"link-to\",\"h1\",\"i18n\",\"if\",\"d-button\",\"action\",\"hr\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/automation/admin/templates/admin-plugins-discourse-automation.hbs",
    "isStrictMode": false
  });
});