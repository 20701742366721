define("discourse/plugins/chat/discourse/services/chat-thread-pane", ["exports", "@ember/service", "discourse/plugins/chat/discourse/services/chat-channel-pane"], function (_exports, _service, _chatChannelPane) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatThreadPane extends _chatChannelPane.default {
    static #_ = (() => dt7948.g(this.prototype, "chat", [_service.service]))();
    #chat = (() => (dt7948.i(this, "chat"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    get thread() {
      return this.channel?.activeThread;
    }
    get isOpened() {
      return this.router.currentRoute.name === "chat.channel.thread" || this.router.currentRoute.name === "chat.channel.thread.index";
    }
    get selectedMessageIds() {
      return this.thread.messagesManager.selectedMessages.mapBy("id");
    }
    async close() {
      await this.router.transitionTo("chat.channel", ...this.channel.routeModels);
    }
    async open(thread) {
      await this.router.transitionTo("chat.channel.thread", ...thread.routeModels);
    }
  }
  _exports.default = ChatThreadPane;
});