define("discourse/plugins/discourse-ai/discourse/components/model-accuracies", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @accuracies}}
    <table class="reviewable-scores">
      <tbody>
        {{#each-in @accuracies as |model acc|}}
          <tr>
            <td colspan="4">{{i18n "discourse_ai.reviewables.model_used"}}</td>
            <td colspan="3">{{model}}</td>
            <td colspan="4">{{i18n "discourse_ai.reviewables.accuracy"}}</td>
            <td colspan="3">{{acc}}%</td>
          </tr>
        {{/each-in}}
      </tbody>
    </table>
  {{/if}}
  */
  {
    "id": "ik7ZG6Vr",
    "block": "[[[41,[30,1],[[[1,\"  \"],[10,\"table\"],[14,0,\"reviewable-scores\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[30,1]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[14,\"colspan\",\"4\"],[12],[1,[28,[35,7],[\"discourse_ai.reviewables.model_used\"],null]],[13],[1,\"\\n          \"],[10,\"td\"],[14,\"colspan\",\"3\"],[12],[1,[30,3]],[13],[1,\"\\n          \"],[10,\"td\"],[14,\"colspan\",\"4\"],[12],[1,[28,[35,7],[\"discourse_ai.reviewables.accuracy\"],null]],[13],[1,\"\\n          \"],[10,\"td\"],[14,\"colspan\",\"3\"],[12],[1,[30,2]],[1,\"%\"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2,3]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@accuracies\",\"acc\",\"model\"],false,[\"if\",\"table\",\"tbody\",\"each\",\"-each-in\",\"tr\",\"td\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/components/model-accuracies.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "model-accuracies"));
});