define("discourse/plugins/docker_manager/discourse/templates/update-processes", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DockerManager::Console @output={{this.model.output}} />
  */
  {
    "id": "Y6yF+lGW",
    "block": "[[[8,[39,0],null,[[\"@output\"],[[30,0,[\"model\",\"output\"]]]],null]],[],false,[\"docker-manager/console\"]]",
    "moduleName": "discourse/plugins/docker_manager/discourse/templates/update-processes.hbs",
    "isStrictMode": false
  });
});