define("discourse/plugins/chat/discourse/modifiers/chat/on-animation-end", ["exports", "@ember/destroyable", "@ember/runloop", "ember-modifier", "discourse/lib/decorators"], function (_exports, _destroyable, _runloop, _emberModifier, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatOnAnimationEnd extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [fn] = _ref;
      this.element = element;
      this.fn = fn;
      this.handler = (0, _runloop.schedule)("afterRender", () => {
        this.element.addEventListener("animationend", this.handleAnimationEnd);
      });
    }
    handleAnimationEnd() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.fn?.(this.element);
    }
    static #_ = (() => dt7948.n(this.prototype, "handleAnimationEnd", [_decorators.bind]))();
    cleanup() {
      (0, _runloop.cancel)(this.handler);
      this.element?.removeEventListener("animationend", this.handleAnimationEnd);
    }
  }
  _exports.default = ChatOnAnimationEnd;
});