define("discourse/plugins/automation/admin/lib/fabricators", ["exports", "@ember/application/instance", "@ember/owner", "discourse/lib/fabricators", "discourse/plugins/automation/admin/models/discourse-automation-automation", "discourse/plugins/automation/admin/models/discourse-automation-field"], function (_exports, _instance, _owner, _fabricators, _discourseAutomationAutomation, _discourseAutomationField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  Fabricators are used to create fake data for testing purposes.
  The following fabricators are available in lib folder to allow
  styleguide to use them, and eventually to generate dummy data
  in a placeholder component. It should not be used for any other case.
  */

  class AutomationFabricators {
    constructor(owner) {
      if (owner && !(owner instanceof _instance.default)) {
        throw new Error("First argument of AutomationFabricators constructor must be the owning ApplicationInstance");
      }
      (0, _owner.setOwner)(this, owner);
    }
    field() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const template = args.template || {};
      template.accepts_placeholders = args.accepts_placeholders ?? true;
      template.accepted_contexts = args.accepted_contexts ?? [];
      template.name = args.name ?? "name";
      template.component = args.component ?? "boolean";
      template.value = args.value ?? false;
      template.is_required = args.is_required ?? false;
      template.extra = args.extra ?? {};
      return _discourseAutomationField.default.create(template, {
        type: args.target ?? "script",
        name: "script_name"
      });
    }
    automation() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const automation = new _discourseAutomationAutomation.default();
      automation.id = args.id || (0, _fabricators.incrementSequence)();
      automation.trigger = {
        id: (0, _fabricators.incrementSequence)().toString()
      };
      automation.script = {
        id: (0, _fabricators.incrementSequence)().toString()
      };
      return automation;
    }
  }
  _exports.default = AutomationFabricators;
});