define("discourse/plugins/automation/admin/adapters/discourse-automation-triggerable", ["exports", "discourse/plugins/automation/admin/adapters/discourse-automation-adapter"], function (_exports, _discourseAutomationAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TriggerableAdapter extends _discourseAutomationAdapter.default {
    jsonMode = true;
    apiNameFor() {
      return "triggerable";
    }
  }
  _exports.default = TriggerableAdapter;
});