define("discourse/plugins/discourse-ai/discourse/templates/admin-plugins/show/discourse-ai-embeddings/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AiEmbeddingsListEditor
    @embeddings={{this.allEmbeddings}}
    @currentEmbedding={{this.model}}
  />
  */
  {
    "id": "vcJaiUO5",
    "block": "[[[8,[39,0],null,[[\"@embeddings\",\"@currentEmbedding\"],[[30,0,[\"allEmbeddings\"]],[30,0,[\"model\"]]]],null]],[],false,[\"ai-embeddings-list-editor\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/templates/admin-plugins/show/discourse-ai-embeddings/edit.hbs",
    "isStrictMode": false
  });
});