define("discourse/plugins/chat/discourse/lib/chat-ios-hacks", ["exports", "discourse/services/capabilities"], function (_exports, _capabilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stackingContextFix = stackingContextFix;
  // since -webkit-overflow-scrolling: touch can't be used anymore to disable momentum scrolling
  // we use different hacks to work around this
  // if you change any line in this method, make sure to test on iOS
  function stackingContextFix(scrollable, callback) {
    callback?.();
    if (_capabilities.capabilities.isIOS) {
      // stores scroll position otherwise we will lose it
      const currentScroll = scrollable.scrollTop;
      const display = scrollable.style.display;
      scrollable.style.display = "none"; // forces redraw on the container
      scrollable.offsetHeight; // triggers a reflow
      scrollable.style.display = display;
      scrollable.scrollTop = currentScroll;
    }
  }
});