define("discourse/plugins/discourse-ai/discourse/connectors/edit-topic-title__after/ai-title-suggestion", ["exports", "@glimmer/component", "discourse/plugins/discourse-ai/discourse/components/suggestion-menus/ai-title-suggester", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper", "@ember/component", "@ember/template-factory"], function (_exports, _component, _aiTitleSuggester, _showAiHelper, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiTitleSuggestion extends _component.default {
    static shouldRender(outletArgs, helper) {
      return (0, _showAiHelper.showComposerAiHelper)(outletArgs?.composer, helper.siteSettings, helper.currentUser, "suggestions");
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <AiTitleSuggester @buffered={{@outletArgs.buffered}} />
      
    */
    {
      "id": "IVWdzZFY",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@buffered\"],[[30,1,[\"buffered\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/edit-topic-title__after/ai-title-suggestion.js",
      "scope": () => [_aiTitleSuggester.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiTitleSuggestion;
});