define("discourse/plugins/chat/discourse/templates/chat", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id="chat-progress-bar-container"></div>
  
  <div
    class={{concat-class
      "full-page-chat"
      (if this.shouldUseCoreSidebar "full-page-chat-sidebar-enabled")
    }}
  >
    {{#if this.shouldUseChatSidebar}}
      <ChannelsList />
    {{/if}}
  
    <div
      id="main-chat-outlet"
      class={{concat-class "main-chat-outlet" this.mainOutletModifierClasses}}
    >
      {{outlet}}
      {{#if this.shouldUseChatFooter}}
        <ChatFooter />
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "VNfkYFy3",
    "block": "[[[10,0],[14,1,\"chat-progress-bar-container\"],[12],[13],[1,\"\\n\\n\"],[10,0],[15,0,[28,[37,1],[\"full-page-chat\",[52,[30,0,[\"shouldUseCoreSidebar\"]],\"full-page-chat-sidebar-enabled\"]],null]],[12],[1,\"\\n\"],[41,[30,0,[\"shouldUseChatSidebar\"]],[[[1,\"    \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,1,\"main-chat-outlet\"],[15,0,[28,[37,1],[\"main-chat-outlet\",[30,0,[\"mainOutletModifierClasses\"]]],null]],[12],[1,\"\\n    \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[41,[30,0,[\"shouldUseChatFooter\"]],[[[1,\"      \"],[8,[39,6],null,null,null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"concat-class\",\"if\",\"channels-list\",\"component\",\"-outlet\",\"chat-footer\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/chat.hbs",
    "isStrictMode": false
  });
});