define("discourse/plugins/chat/discourse/services/chat-composer-warnings-tracker", ["exports", "@glimmer/tracking", "@ember/runloop", "@ember/service", "discourse/lib/ajax", "discourse/lib/debounce", "discourse/lib/decorators"], function (_exports, _tracking, _runloop, _service, _ajax, _debounce, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const MENTION_RESULT = {
    invalid: -1,
    unreachable: 0,
    over_members_limit: 1
  };
  const MENTION_DEBOUNCE_MS = 1000;
  class ChatComposerWarningsTracker extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "unreachableGroupMentions", [_tracking.tracked], function () {
      return [];
    }))();
    #unreachableGroupMentions = (() => (dt7948.i(this, "unreachableGroupMentions"), void 0))(); // Track mention hints to display warnings
    static #_3 = (() => dt7948.g(this.prototype, "overMembersLimitGroupMentions", [_tracking.tracked], function () {
      return [];
    }))();
    #overMembersLimitGroupMentions = (() => (dt7948.i(this, "overMembersLimitGroupMentions"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "tooManyMentions", [_tracking.tracked], function () {
      return false;
    }))();
    #tooManyMentions = (() => (dt7948.i(this, "tooManyMentions"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "channelWideMentionDisallowed", [_tracking.tracked], function () {
      return false;
    }))();
    #channelWideMentionDisallowed = (() => (dt7948.i(this, "channelWideMentionDisallowed"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "mentionsCount", [_tracking.tracked], function () {
      return 0;
    }))();
    #mentionsCount = (() => (dt7948.i(this, "mentionsCount"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "mentionsTimer", [_tracking.tracked], function () {
      return null;
    }))();
    #mentionsTimer = (() => (dt7948.i(this, "mentionsTimer"), void 0))();
    // Complimentary structure to avoid repeating mention checks.
    _mentionWarningsSeen = {};
    willDestroy() {
      (0, _runloop.cancel)(this.mentionsTimer);
    }
    reset() {
      this.#resetMentionStats();
      this.mentionsCount = 0;
      (0, _runloop.cancel)(this.mentionsTimer);
    }
    static #_8 = (() => dt7948.n(this.prototype, "reset", [_decorators.bind]))();
    trackMentions(currentMessage, skipDebounce) {
      if (skipDebounce) {
        return this._trackMentions(currentMessage);
      }
      this.mentionsTimer = (0, _debounce.default)(this, this._trackMentions, currentMessage, MENTION_DEBOUNCE_MS);
    }
    static #_9 = (() => dt7948.n(this.prototype, "trackMentions", [_decorators.bind]))();
    _trackMentions(currentMessage) {
      if (!this.siteSettings.enable_mentions) {
        return;
      }
      currentMessage.parseMentions().then(mentions => {
        this.mentionsCount = mentions?.length;
        if (this.mentionsCount > 0) {
          this.tooManyMentions = this.mentionsCount > this.siteSettings.max_mentions_per_chat_message;
          if (!this.tooManyMentions) {
            const newMentions = mentions.filter(mention => !(mention in this._mentionWarningsSeen));
            this.channelWideMentionDisallowed = !currentMessage.channel.allowChannelWideMentions && (mentions.includes("here") || mentions.includes("all"));
            if (newMentions?.length > 0) {
              this.#recordNewWarnings(newMentions, mentions);
            } else {
              this.#rebuildWarnings(mentions);
            }
          }
        } else {
          this.#resetMentionStats();
        }
      });
    }
    static #_10 = (() => dt7948.n(this.prototype, "_trackMentions", [_decorators.bind]))();
    #resetMentionStats() {
      this.tooManyMentions = false;
      this.channelWideMentionDisallowed = false;
      this.unreachableGroupMentions = [];
      this.overMembersLimitGroupMentions = [];
    }
    #recordNewWarnings(newMentions, mentions) {
      (0, _ajax.ajax)("/chat/api/mentions/groups.json", {
        data: {
          mentions: newMentions
        }
      }).then(newWarnings => {
        newWarnings.unreachable.forEach(warning => {
          this._mentionWarningsSeen[warning] = MENTION_RESULT["unreachable"];
        });
        newWarnings.over_members_limit.forEach(warning => {
          this._mentionWarningsSeen[warning] = MENTION_RESULT["over_members_limit"];
        });
        newWarnings.invalid.forEach(warning => {
          this._mentionWarningsSeen[warning] = MENTION_RESULT["invalid"];
        });
        this.#rebuildWarnings(mentions);
      }).catch(this.#rebuildWarnings(mentions));
    }
    #rebuildWarnings(mentions) {
      const newWarnings = mentions.reduce((memo, mention) => {
        if (mention in this._mentionWarningsSeen && !(this._mentionWarningsSeen[mention] === MENTION_RESULT["invalid"])) {
          if (this._mentionWarningsSeen[mention] === MENTION_RESULT["unreachable"]) {
            memo[0].push(mention);
          } else {
            memo[1].push(mention);
          }
        }
        return memo;
      }, [[], []]);
      this.unreachableGroupMentions = newWarnings[0];
      this.overMembersLimitGroupMentions = newWarnings[1];
    }
  }
  _exports.default = ChatComposerWarningsTracker;
});