define("discourse/plugins/discourse-ai/discourse/components/ai-quota-duration-selector", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/modifier", "@ember/object", "discourse-i18n", "select-kit/components/combo-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _modifier, _object, _discourseI18n, _comboBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DURATION_PRESETS = [{
    id: "3600",
    seconds: 3600,
    nameKey: "discourse_ai.llms.quotas.durations.hour"
  }, {
    id: "21600",
    seconds: 21600,
    nameKey: "discourse_ai.llms.quotas.durations.six_hours"
  }, {
    id: "86400",
    seconds: 86400,
    nameKey: "discourse_ai.llms.quotas.durations.day"
  }, {
    id: "604800",
    seconds: 604800,
    nameKey: "discourse_ai.llms.quotas.durations.week"
  }, {
    id: "custom",
    nameKey: "discourse_ai.llms.quotas.durations.custom"
  }];
  class DurationSelector extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "selectedPresetId", [_tracking.tracked], function () {
      return "86400";
    }))();
    #selectedPresetId = (() => (dt7948.i(this, "selectedPresetId"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "customHours", [_tracking.tracked], function () {
      return null;
    }))();
    #customHours = (() => (dt7948.i(this, "customHours"), void 0))();
    constructor() {
      super(...arguments);
      const seconds = this.args.value;
      const preset = DURATION_PRESETS.find(p => p.seconds === seconds);
      if (preset) {
        this.selectedPresetId = preset.id;
      } else {
        this.selectedPresetId = "custom";
        this.customHours = Math.round(seconds / 3600);
      }
    }
    get presetOptions() {
      return DURATION_PRESETS.map(preset => ({
        id: preset.id,
        name: (0, _discourseI18n.i18n)(preset.nameKey),
        seconds: preset.seconds
      }));
    }
    get isCustom() {
      return this.selectedPresetId === "custom";
    }
    get currentDurationSeconds() {
      if (this.isCustom) {
        return this.customHours ? this.customHours * 3600 : 0;
      } else {
        return parseInt(this.selectedPresetId, 10);
      }
    }
    onPresetChange(value) {
      this.selectedPresetId = value;
      this.updateValue();
    }
    static #_3 = (() => dt7948.n(this.prototype, "onPresetChange", [_object.action]))();
    onCustomHoursChange(event) {
      this.customHours = parseInt(event.target.value, 10);
      this.updateValue();
    }
    static #_4 = (() => dt7948.n(this.prototype, "onCustomHoursChange", [_object.action]))();
    updateValue() {
      if (this.args.onChange) {
        this.args.onChange(this.currentDurationSeconds);
      }
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="duration-selector">
          <ComboBox
            @content={{this.presetOptions}}
            @value={{this.selectedPresetId}}
            @onChange={{this.onPresetChange}}
            class="duration-selector__preset"
          />
    
          {{#if this.isCustom}}
            <div class="duration-selector__custom">
              <input
                type="number"
                value={{this.customHours}}
                class="duration-selector__hours-input"
                min="1"
                {{on "input" this.onCustomHoursChange}}
              />
              <span class="duration-selector__hours-label">
                {{i18n "discourse_ai.llms.quotas.hours"}}
              </span>
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "qzu/tJzR",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"duration-selector\"],[12],[1,\"\\n      \"],[8,[32,0],[[24,0,\"duration-selector__preset\"]],[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"presetOptions\"]],[30,0,[\"selectedPresetId\"]],[30,0,[\"onPresetChange\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"isCustom\"]],[[[1,\"        \"],[10,0],[14,0,\"duration-selector__custom\"],[12],[1,\"\\n          \"],[11,\"input\"],[16,2,[30,0,[\"customHours\"]]],[24,0,\"duration-selector__hours-input\"],[24,\"min\",\"1\"],[24,4,\"number\"],[4,[32,1],[\"input\",[30,0,[\"onCustomHoursChange\"]]],null],[12],[13],[1,\"\\n          \"],[10,1],[14,0,\"duration-selector__hours-label\"],[12],[1,\"\\n            \"],[1,[28,[32,2],[\"discourse_ai.llms.quotas.hours\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-quota-duration-selector.js",
      "scope": () => [_comboBox.default, _modifier.on, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DurationSelector;
});