define("discourse/plugins/discourse-ai/discourse/admin/adapters/ai-llm", ["exports", "discourse/adapters/rest"], function (_exports, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Adapter extends _rest.default {
    jsonMode = true;
    basePath() {
      return "/admin/plugins/discourse-ai/";
    }
    pathFor(store, type, findArgs) {
      // removes underscores which are implemented in base
      let path = this.basePath(store, type, findArgs) + store.pluralize(this.apiNameFor(type));
      return this.appendQueryParams(path, findArgs);
    }
    apiNameFor() {
      return "ai-llm";
    }
  }
  _exports.default = Adapter;
});