define("discourse/plugins/docker_manager/discourse/helpers/new-commits", ["exports", "@ember/template", "discourse-i18n"], function (_exports, _template, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = newCommits;
  function newCommits(commitsBehind, oldSha, newSha, url) {
    if (!commitsBehind) {
      return "";
    }
    if (parseInt(commitsBehind, 10) === 0) {
      return "";
    }
    const description = _discourseI18n.default.t("admin.docker.commits", {
      count: commitsBehind
    });
    if (!url) {
      return description;
    }
    const _url = url.substr(0, url.search(/(\.git)?$/));
    return (0, _template.htmlSafe)(`<a href='${_url}/compare/${oldSha}...${newSha}'>${description}</a>`);
  }
});