define("discourse/plugins/discourse-ai/discourse/connectors/full-page-search-below-search-header/ai-full-page-search", ["exports", "@glimmer/component", "discourse/plugins/discourse-ai/discourse/components/ai-full-page-search", "@ember/component", "@ember/template-factory"], function (_exports, _component, _aiFullPageSearch, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiFullPageSearchConnector extends _component.default {
    static shouldRender(_args, _ref) {
      let {
        siteSettings
      } = _ref;
      return siteSettings.ai_embeddings_semantic_search_enabled;
    }
    static #_ = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <AiFullPageSearch
          @sortOrder={{@outletArgs.sortOrder}}
          @searchTerm={{@outletArgs.search}}
          @searchType={{@outletArgs.type}}
          @addSearchResults={{@outletArgs.addSearchResults}}
        />
      
    */
    {
      "id": "4RggybC1",
      "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@sortOrder\",\"@searchTerm\",\"@searchType\",\"@addSearchResults\"],[[30,1,[\"sortOrder\"]],[30,1,[\"search\"]],[30,1,[\"type\"]],[30,1,[\"addSearchResults\"]]]],null],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/full-page-search-below-search-header/ai-full-page-search.js",
      "scope": () => [_aiFullPageSearch.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiFullPageSearchConnector;
});