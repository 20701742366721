define("discourse/plugins/discourse-solved/discourse/routes/user-activity-solved", ["exports", "discourse/routes/user-activity-stream", "discourse-i18n"], function (_exports, _userActivityStream, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserActivitySolved extends _userActivityStream.default {
    userActionType = 15;
    noContentHelpKey = "solved.no_solutions";
    emptyState() {
      const user = this.modelFor("user");
      let title, body;
      if (this.isCurrentUser(user)) {
        title = (0, _discourseI18n.i18n)("solved.no_solved_topics_title");
        body = (0, _discourseI18n.i18n)("solved.no_solved_topics_body");
      } else {
        title = (0, _discourseI18n.i18n)("solved.no_solved_topics_title_others", {
          username: user.username
        });
        body = "";
      }
      return {
        title,
        body
      };
    }
  }
  _exports.default = UserActivitySolved;
});