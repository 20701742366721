define("discourse/plugins/discourse-ai/discourse/services/quick-search", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class QuickSearch extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "invalidTerm", [_tracking.tracked], function () {
      return false;
    }))();
    #invalidTerm = (() => (dt7948.i(this, "invalidTerm"), void 0))();
  }
  _exports.default = QuickSearch;
});