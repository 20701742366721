define("discourse/plugins/chat/discourse/components/thread-notifications-tracking", ["exports", "discourse/components/notifications-tracking", "discourse/plugins/chat/discourse/lib/chat-notification-levels", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _notificationsTracking, _chatNotificationLevels, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ThreadNotificationsTracking = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <NotificationsTracking
      @onChange={{@onChange}}
      @levels={{threadNotificationButtonLevels}}
      @levelId={{@levelId}}
      @showCaret={{false}}
      @showFullTitle={{false}}
      @prefix="chat.thread.notifications"
      class="thread-notifications-tracking"
      @triggerClass="btn-transparent"
    />
  
  */
  {
    "id": "EYsKXFrP",
    "block": "[[[1,\"\\n  \"],[8,[32,0],[[24,0,\"thread-notifications-tracking\"]],[[\"@onChange\",\"@levels\",\"@levelId\",\"@showCaret\",\"@showFullTitle\",\"@prefix\",\"@triggerClass\"],[[30,1],[32,1],[30,2],false,false,\"chat.thread.notifications\",\"btn-transparent\"]],null],[1,\"\\n\"]],[\"@onChange\",\"@levelId\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/thread-notifications-tracking.js",
    "scope": () => [_notificationsTracking.default, _chatNotificationLevels.threadNotificationButtonLevels],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "thread-notifications-tracking:ThreadNotificationsTracking"));
  var _default = _exports.default = ThreadNotificationsTracking;
});