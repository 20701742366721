define("discourse/plugins/discourse-ai/discourse/routes/discourse-ai-shared-conversation-show", ["exports", "@ember/service", "discourse/routes/discourse"], function (_exports, _service, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscourseAiSharedConversationShowRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    beforeModel(transition) {
      if (this.currentUser?.user_option?.external_links_in_new_tab) {
        window.open(transition.intent.url, "_blank");
      } else {
        this.redirect(transition.intent.url);
      }
      transition.abort();
    }
    redirect(url) {
      window.location = url;
    }
  }
  _exports.default = DiscourseAiSharedConversationShowRoute;
});