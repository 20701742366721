define("discourse/plugins/automation/admin/controllers/admin-plugins-discourse-automation-index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse/lib/escape", "discourse-i18n"], function (_exports, _controller, _object, _service, _ajaxError, _escape, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AutomationIndex extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    editAutomation(automation) {
      this.router.transitionTo("adminPlugins.discourse-automation.edit", automation.id);
    }
    static #_3 = (() => dt7948.n(this.prototype, "editAutomation", [_object.action]))();
    newAutomation() {
      this.router.transitionTo("adminPlugins.discourse-automation.new");
    }
    static #_4 = (() => dt7948.n(this.prototype, "newAutomation", [_object.action]))();
    destroyAutomation(automation) {
      this.dialog.deleteConfirm({
        message: (0, _discourseI18n.i18n)("discourse_automation.destroy_automation.confirm", {
          name: (0, _escape.default)(automation.name)
        }),
        didConfirm: () => {
          return automation.destroyRecord().then(() => this.send("triggerRefresh")).catch(_ajaxError.popupAjaxError);
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "destroyAutomation", [_object.action]))();
  }
  _exports.default = AutomationIndex;
});