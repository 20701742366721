define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-usage", ["exports", "@ember/service", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _service, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscourseAiUsageRoute extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "store", [_service.service]))();
    #store = (() => (dt7948.i(this, "store"), void 0))();
    model() {
      return (0, _ajax.ajax)("/admin/plugins/discourse-ai/ai-usage.json");
    }
  }
  _exports.default = DiscourseAiUsageRoute;
});