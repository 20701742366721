define("discourse/plugins/discourse-ai/discourse/components/ai-composer-helper-menu", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/owner", "@ember/service", "discourse-i18n", "float-kit/components/d-toast", "float-kit/lib/d-toast-instance", "discourse/plugins/discourse-ai/discourse/components/ai-helper-options-list", "discourse/plugins/discourse-ai/discourse/components/modal/diff-modal", "discourse/plugins/discourse-ai/discourse/components/modal/thumbnail-suggestions", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _owner, _service, _discourseI18n, _dToast, _dToastInstance, _aiHelperOptionsList, _diffModal, _thumbnailSuggestions, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiComposerHelperMenu extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "site", [_service.service]))();
    #site = (() => (dt7948.i(this, "site"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "newSelectedText", [_tracking.tracked]))();
    #newSelectedText = (() => (dt7948.i(this, "newSelectedText"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "diff", [_tracking.tracked]))();
    #diff = (() => (dt7948.i(this, "diff"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "customPromptValue", [_tracking.tracked], function () {
      return "";
    }))();
    #customPromptValue = (() => (dt7948.i(this, "customPromptValue"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "noContentError", [_tracking.tracked], function () {
      return false;
    }))();
    #noContentError = (() => (dt7948.i(this, "noContentError"), void 0))();
    prompts = [];
    promptTypes = {};
    constructor() {
      super(...arguments);
      if (this.args.data.toolbarEvent.getText().length === 0) {
        this.noContentError = true;
      }
    }
    get helperOptions() {
      let prompts = this.currentUser?.ai_helper_prompts;
      prompts = prompts.filter(p => p.location.includes("composer")).filter(p => p.name !== "generate_titles").map(p => {
        // AI helper by default returns interface locale on translations
        // Since we want site default translations (and we are using: force_default_locale)
        // we need to replace the translated_name with the site default locale name
        const siteLocale = this.siteSettings.default_locale;
        const availableLocales = JSON.parse(this.siteSettings.available_locales);
        const locale = availableLocales.find(l => l.value === siteLocale);
        const translatedName = (0, _discourseI18n.i18n)("discourse_ai.ai_helper.context_menu.translate_prompt", {
          language: locale.name
        });
        if (p.name === "translate") {
          return {
            ...p,
            translated_name: translatedName
          };
        }
        return p;
      });
      // Find the custom_prompt object and move it to the beginning of the array
      const customPromptIndex = prompts.findIndex(p => p.name === "custom_prompt");
      if (customPromptIndex !== -1) {
        const customPrompt = prompts.splice(customPromptIndex, 1)[0];
        prompts.unshift(customPrompt);
      }
      if (!this.currentUser?.can_use_custom_prompts) {
        prompts = prompts.filter(p => p.name !== "custom_prompt");
      }
      prompts.forEach(p => {
        this.prompts[p.id] = p;
      });
      this.promptTypes = prompts.reduce((memo, p) => {
        memo[p.name] = p.prompt_type;
        return memo;
      }, {});
      return prompts;
    }
    get toast() {
      const owner = (0, _owner.getOwner)(this);
      const options = {
        close: () => this.args.close(),
        duration: 3000,
        data: {
          theme: "error",
          icon: "triangle-exclamation",
          message: (0, _discourseI18n.i18n)("discourse_ai.ai_helper.no_content_error")
        }
      };
      const custom = class CustomToastInstance extends _dToastInstance.default {
        constructor() {
          super(owner, options);
        }
        close() {
          this.options.close();
        }
        static #_ = (() => dt7948.n(this.prototype, "close", [_object.action]))();
      };
      return new custom(owner, options);
    }
    async suggestChanges(option) {
      await this.args.close();
      if (option.name === "illustrate_post") {
        return this.modal.show(_thumbnailSuggestions.default, {
          model: {
            mode: option.id,
            selectedText: this.args.data.selectedText,
            thumbnails: this.thumbnailSuggestions
          }
        });
      }
      return this.modal.show(_diffModal.default, {
        model: {
          mode: option.id,
          selectedText: this.args.data.selectedText,
          revert: this.undoAiAction,
          toolbarEvent: this.args.data.toolbarEvent,
          customPromptValue: this.customPromptValue
        }
      });
    }
    static #_9 = (() => dt7948.n(this.prototype, "suggestChanges", [_object.action]))();
    closeMenu() {
      this.customPromptValue = "";
      this.args.close();
    }
    static #_10 = (() => dt7948.n(this.prototype, "closeMenu", [_object.action]))();
    static #_11 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.noContentError}}
          <DToast @toast={{this.toast}} />
        {{else}}
          <div class="ai-composer-helper-menu">
            {{#if this.site.mobileView}}
              <div class="ai-composer-helper-menu__selected-text">
                {{@data.selectedText}}
              </div>
            {{/if}}
    
            <AiHelperOptionsList
              @options={{this.helperOptions}}
              @customPromptValue={{this.customPromptValue}}
              @performAction={{this.suggestChanges}}
              @shortcutVisible={{true}}
            />
          </div>
        {{/if}}
      
    */
    {
      "id": "2ahoy4kS",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"noContentError\"]],[[[1,\"      \"],[8,[32,0],null,[[\"@toast\"],[[30,0,[\"toast\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"ai-composer-helper-menu\"],[12],[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"          \"],[10,0],[14,0,\"ai-composer-helper-menu__selected-text\"],[12],[1,\"\\n            \"],[1,[30,1,[\"selectedText\"]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[32,1],null,[[\"@options\",\"@customPromptValue\",\"@performAction\",\"@shortcutVisible\"],[[30,0,[\"helperOptions\"]],[30,0,[\"customPromptValue\"]],[30,0,[\"suggestChanges\"]],true]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@data\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/ai-composer-helper-menu.js",
      "scope": () => [_dToast.default, _aiHelperOptionsList.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiComposerHelperMenu;
});