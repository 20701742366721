define("discourse/plugins/chat/discourse/lib/fabricators", ["exports", "@ember/application/instance", "@ember/owner", "discourse/lib/fabricators", "discourse/models/category", "discourse/plugins/chat/discourse/models/chat-channel", "discourse/plugins/chat/discourse/models/chat-direct-message", "discourse/plugins/chat/discourse/models/chat-message", "discourse/plugins/chat/discourse/models/chat-message-reaction", "discourse/plugins/chat/discourse/models/chat-thread", "discourse/plugins/chat/discourse/models/chat-thread-preview"], function (_exports, _instance, _owner, _fabricators, _category, _chatChannel, _chatDirectMessage, _chatMessage, _chatMessageReaction, _chatThread, _chatThreadPreview) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  Fabricators are used to create fake data for testing purposes.
  The following fabricators are available in lib folder to allow
  styleguide to use them, and eventually to generate dummy data
  in a placeholder component. It should not be used for any other case.
  */

  class ChatFabricators {
    constructor(owner) {
      if (owner && !(owner instanceof _instance.default)) {
        throw new Error("First argument of ChatFabricators constructor must be the owning ApplicationInstance");
      }
      (0, _owner.setOwner)(this, owner);
      this.coreFabricators = new _fabricators.default(owner);
    }
    message() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const channel = args.channel || this.channel();
      const message = _chatMessage.default.create(channel, Object.assign({
        id: args.id || (0, _fabricators.incrementSequence)(),
        user: args.user || this.coreFabricators.user(),
        message: args.message || "@discobot **abc**defghijklmnopqrstuvwxyz [discourse](discourse.org) :rocket: ",
        created_at: args.created_at || moment()
      }, args));
      const excerptLength = 50;
      const text = message.message.toString();
      if (text.length <= excerptLength) {
        message.excerpt = text;
      } else {
        message.excerpt = text.slice(0, excerptLength) + "...";
      }
      return message;
    }
    channel() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const id = args.id || (0, _fabricators.incrementSequence)();
      const chatable = args.chatable || this.coreFabricators.category();
      const channel = _chatChannel.default.create({
        id,
        chatable_type: (chatable instanceof _category.default ? _chatChannel.CHATABLE_TYPES.categoryChannel : _chatChannel.CHATABLE_TYPES.directMessageChannel) || chatable?.type || args.chatable_type,
        chatable_id: chatable?.id || args.chatable_id,
        title: args.title ? args.title : chatable instanceof _category.default ? chatable.name : null,
        description: args.description,
        chatable,
        status: args.status || _chatChannel.CHANNEL_STATUSES.open,
        slug: chatable?.slug || chatable instanceof _category.default ? chatable.slug : null,
        meta: {
          can_delete_self: true,
          ...(args.meta || {})
        },
        archive_failed: args.archive_failed ?? false,
        memberships_count: args.memberships_count ?? 0
      });
      channel.lastMessage = this.message({
        channel
      });
      return channel;
    }
    directMessage() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return _chatDirectMessage.default.create({
        group: args.group ?? false,
        users: args.users ?? [this.coreFabricators.user(), this.coreFabricators.user()]
      });
    }
    directMessageChannel() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const directMessage = args.chatable || this.directMessage({
        id: args.chatable_id || (0, _fabricators.incrementSequence)(),
        group: args.group ?? false,
        users: args.users
      });
      return this.channel(Object.assign(args, {
        chatable_type: _chatChannel.CHATABLE_TYPES.directMessageChannel,
        chatable_id: directMessage.id,
        chatable: directMessage,
        memberships_count: directMessage.users.length
      }));
    }
    thread() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const channel = args.channel || this.channel();
      return _chatThread.default.create(channel, {
        id: args.id || (0, _fabricators.incrementSequence)(),
        title: args.title,
        original_message: args.original_message || this.message({
          channel
        }),
        preview: args.preview || this.threadPreview({
          channel
        })
      });
    }
    threadPreview() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return _chatThreadPreview.default.create({
        last_reply_id: args.last_reply_id || (0, _fabricators.incrementSequence)(),
        last_reply_created_at: args.last_reply_created_at || Date.now(),
        last_reply_excerpt: args.last_reply_excerpt || "This is a reply",
        participant_count: args.participant_count ?? 0,
        participant_users: args.participant_users ?? []
      });
    }
    reaction() {
      let args = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return _chatMessageReaction.default.create({
        count: args.count ?? 1,
        users: args.users || [this.coreFabricators.user()],
        emoji: args.emoji || "heart",
        reacted: args.reacted ?? false
      });
    }
  }
  _exports.default = ChatFabricators;
});