define("discourse/plugins/discourse-ai/discourse/components/ai-tool-selector", ["exports", "@ember/object/computed", "@ember-decorators/object", "select-kit/components/multi-select", "select-kit/components/select-kit"], function (_exports, _computed, _object, _multiSelect, _selectKit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AiToolSelector = dt7948.c(class AiToolSelector extends _multiSelect.default {
    static #_ = (() => dt7948.g(this.prototype, "content", [(0, _computed.readOnly)("tools")]))();
    #content = (() => (dt7948.i(this, "content"), void 0))();
    value = "";
    _modelDisabledChanged() {
      this.selectKit.options.set("disabled", this.get("attrs.disabled.value"));
    }
    static #_2 = (() => dt7948.n(this.prototype, "_modelDisabledChanged", [(0, _object.observes)("attrs.disabled")]))();
  }, [(0, _selectKit.selectKitOptions)({
    filterable: true
  })]);
  var _default = _exports.default = AiToolSelector;
});