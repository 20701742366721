define("discourse/plugins/chat/discourse/templates/admin-plugins/show/discourse-chat-incoming-webhooks/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DBreadcrumbsItem
    @path="/admin/plugins/chat/hooks"
    @label={{i18n "chat.incoming_webhooks.title"}}
  />
  
  <div class="discourse-chat-incoming-webhooks admin-detail">
    <DPageSubheader
      @titleLabel={{i18n "chat.incoming_webhooks.title"}}
      @descriptionLabel={{i18n "chat.incoming_webhooks.instructions"}}
    >
      <:actions as |actions|>
        <actions.Primary
          @label="chat.incoming_webhooks.new"
          @title="chat.incoming_webhooks.new"
          @route="adminPlugins.show.discourse-chat-incoming-webhooks.new"
          @routeModels="chat"
          @icon="plus"
          class="admin-incoming-webhooks-new"
        />
      </:actions>
    </DPageSubheader>
  
    <div class="incoming-chat-webhooks">
      {{#if this.model.incoming_chat_webhooks}}
        <AdminChatIncomingWebhooksList
          @webhooks={{this.model.incoming_chat_webhooks}}
        />
      {{else}}
        {{i18n "chat.incoming_webhooks.none"}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "25cRvXzV",
    "block": "[[[8,[39,0],null,[[\"@path\",\"@label\"],[\"/admin/plugins/chat/hooks\",[28,[37,1],[\"chat.incoming_webhooks.title\"],null]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"discourse-chat-incoming-webhooks admin-detail\"],[12],[1,\"\\n  \"],[8,[39,3],null,[[\"@titleLabel\",\"@descriptionLabel\"],[[28,[37,1],[\"chat.incoming_webhooks.title\"],null],[28,[37,1],[\"chat.incoming_webhooks.instructions\"],null]]],[[\"actions\"],[[[[1,\"\\n      \"],[8,[30,1,[\"Primary\"]],[[24,0,\"admin-incoming-webhooks-new\"]],[[\"@label\",\"@title\",\"@route\",\"@routeModels\",\"@icon\"],[\"chat.incoming_webhooks.new\",\"chat.incoming_webhooks.new\",\"adminPlugins.show.discourse-chat-incoming-webhooks.new\",\"chat\",\"plus\"]],null],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"incoming-chat-webhooks\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"incoming_chat_webhooks\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@webhooks\"],[[30,0,[\"model\",\"incoming_chat_webhooks\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,1],[\"chat.incoming_webhooks.none\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"actions\"],false,[\"d-breadcrumbs-item\",\"i18n\",\"div\",\"d-page-subheader\",\":actions\",\"if\",\"admin-chat-incoming-webhooks-list\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/admin-plugins/show/discourse-chat-incoming-webhooks/index.hbs",
    "isStrictMode": false
  });
});