define("discourse/plugins/chat/discourse/components/collapser", ["exports", "@ember/component", "@ember/object", "@ember-decorators/component", "@ember/template-factory"], function (_exports, _component, _object, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="chat-message-collapser-header">
    {{this.header}}
  
    {{#if this.collapsed}}
      <DButton
        @action={{this.open}}
        @icon="caret-right"
        class="chat-message-collapser-button chat-message-collapser-closed"
      />
    {{else}}
      <DButton
        @action={{this.close}}
        @icon="caret-down"
        class="chat-message-collapser-button chat-message-collapser-opened"
      />
    {{/if}}
  </div>
  
  <div
    class={{concat-class
      "chat-message-collapser-body"
      (if this.collapsed "hidden")
    }}
  >
    {{yield this.collapsed}}
  </div>
  */
  {
    "id": "YcaFagUW",
    "block": "[[[10,0],[14,0,\"chat-message-collapser-header\"],[12],[1,\"\\n  \"],[1,[30,0,[\"header\"]]],[1,\"\\n\\n\"],[41,[30,0,[\"collapsed\"]],[[[1,\"    \"],[8,[39,2],[[24,0,\"chat-message-collapser-button chat-message-collapser-closed\"]],[[\"@action\",\"@icon\"],[[30,0,[\"open\"]],\"caret-right\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],[[24,0,\"chat-message-collapser-button chat-message-collapser-opened\"]],[[\"@action\",\"@icon\"],[[30,0,[\"close\"]],\"caret-down\"]],null],[1,\"\\n\"]],[]]],[13],[1,\"\\n\\n\"],[10,0],[15,0,[28,[37,3],[\"chat-message-collapser-body\",[52,[30,0,[\"collapsed\"]],\"hidden\"]],null]],[12],[1,\"\\n  \"],[18,1,[[30,0,[\"collapsed\"]]]],[1,\"\\n\"],[13]],[\"&default\"],false,[\"div\",\"if\",\"d-button\",\"concat-class\",\"yield\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/collapser.hbs",
    "isStrictMode": false
  });
  const Collapser = dt7948.c(class Collapser extends _component.default {
    collapsed = false;
    header = null;
    onToggle = null;
    open() {
      this.set("collapsed", false);
      this.onToggle?.(false);
    }
    static #_ = (() => dt7948.n(this.prototype, "open", [_object.action]))();
    close() {
      this.set("collapsed", true);
      this.onToggle?.(true);
    }
    static #_2 = (() => dt7948.n(this.prototype, "close", [_object.action]))();
  }, [(0, _component2.tagName)("")]);
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Collapser);
});