define("discourse/plugins/chat/discourse/templates/chat-browse", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="c-routes --browse">
    <Chat::Navbar as |navbar|>
      <navbar.BackButton />
      <navbar.Title @title={{i18n "chat.browse.title"}} />
  
      <navbar.Actions as |a|>
        <a.NewChannelButton />
        <a.OpenDrawerButton />
      </navbar.Actions>
    </Chat::Navbar>
  
    {{outlet}}
  </div>
  */
  {
    "id": "wZDRKpS9",
    "block": "[[[10,0],[14,0,\"c-routes --browse\"],[12],[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"BackButton\"]],null,null,null],[1,\"\\n    \"],[8,[30,1,[\"Title\"]],null,[[\"@title\"],[[28,[37,2],[\"chat.browse.title\"],null]]],null],[1,\"\\n\\n    \"],[8,[30,1,[\"Actions\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[30,2,[\"NewChannelButton\"]],null,null,null],[1,\"\\n      \"],[8,[30,2,[\"OpenDrawerButton\"]],null,null,null],[1,\"\\n    \"]],[2]]]]],[1,\"\\n  \"]],[1]]]]],[1,\"\\n\\n  \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"],[13]],[\"navbar\",\"a\"],false,[\"div\",\"chat/navbar\",\"i18n\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/chat-browse.hbs",
    "isStrictMode": false
  });
});