define("discourse/plugins/chat/discourse/modifiers/chat/on-scroll", ["exports", "@ember/destroyable", "@ember/runloop", "ember-modifier", "discourse/lib/decorators"], function (_exports, _destroyable, _runloop, _emberModifier, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatOnScroll extends _emberModifier.default {
    constructor(owner, args) {
      super(owner, args);
      (0, _destroyable.registerDestructor)(this, instance => instance.cleanup());
    }
    modify(element, _ref) {
      let [callback, options] = _ref;
      this.element = element;
      this.callback = callback;
      this.options = options;
      this.element.addEventListener("scroll", this.throttledCallback, {
        passive: true
      });
    }
    throttledCallback(event) {
      this.throttledHandler = (0, _runloop.throttle)(this, this.callback, event, this.options.delay ?? 100, this.options.immediate ?? false);
    }
    static #_ = (() => dt7948.n(this.prototype, "throttledCallback", [_decorators.bind]))();
    cleanup() {
      (0, _runloop.cancel)(this.throttledHandler);
      this.element.removeEventListener("scroll", this.throttledCallback);
    }
  }
  _exports.default = ChatOnScroll;
});