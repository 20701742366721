define("discourse/plugins/discourse-ai/discourse/connectors/search-menu-before-advanced-search/ai-quick-search-loader", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/loading-spinner", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _loadingSpinner, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiQuickSearchLoader extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "quickSearch", [_service.service]))();
    #quickSearch = (() => (dt7948.i(this, "quickSearch"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.quickSearch.loading}}
          <div class="ai-quick-search-spinner">
            {{loadingSpinner}}
          </div>
        {{/if}}
      
    */
    {
      "id": "apo2cAJf",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"quickSearch\",\"loading\"]],[[[1,\"      \"],[10,0],[14,0,\"ai-quick-search-spinner\"],[12],[1,\"\\n        \"],[1,[32,0]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/search-menu-before-advanced-search/ai-quick-search-loader.js",
      "scope": () => [_loadingSpinner.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiQuickSearchLoader;
});