define("discourse/plugins/chat/discourse/lib/transform-auto-links", ["exports", "discourse/lib/get-url", "discourse/lib/hashtag-decorator"], function (_exports, _getUrl, _hashtagDecorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = transformAutolinks;
  const domParser = new DOMParser();
  function transformAutolinks(cooked) {
    const html = domParser.parseFromString(cooked, "text/html");
    transformMentions(html);
    transformHashtags(html);
    return html.body.innerHTML;
  }
  function transformMentions(html) {
    (html.querySelectorAll("span.mention") || []).forEach(mentionSpan => {
      let mentionLink = document.createElement("a");
      let mentionText = document.createTextNode(mentionSpan.innerText);
      mentionLink.classList.add("mention");
      mentionLink.appendChild(mentionText);
      mentionLink.href = (0, _getUrl.default)(`/u/${mentionSpan.innerText.substring(1)}`);
      mentionSpan.replaceWith(mentionLink);
    });
  }
  function transformHashtags(html) {
    (html.querySelectorAll("span.hashtag-raw") || []).forEach(hashtagSpan => {
      // Doesn't matter what "type" of hashtag we use here, it will get replaced anyway,
      // this is just for the placeholder HTML.
      (0, _hashtagDecorator.generatePlaceholderHashtagHTML)("category", hashtagSpan, {
        id: -1,
        text: "...",
        relative_url: "/",
        slug: "",
        icon: "square-full"
      });
    });
  }
});