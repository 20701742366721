define("discourse/plugins/chat/discourse/routes/admin-plugins-show-discourse-chat-incoming-webhooks-index", ["exports", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/routes/discourse", "discourse-i18n", "discourse/plugins/chat/discourse/models/chat-channel"], function (_exports, _object, _service, _ajax, _ajaxError, _discourse, _discourseI18n, _chatChannel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscourseChatIncomingWebhooksIndex extends _discourse.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    async model() {
      if (!this.currentUser?.admin) {
        return {
          model: null
        };
      }
      try {
        const model = await (0, _ajax.ajax)("/admin/plugins/chat/hooks.json");
        model.chat_channels = model.chat_channels.map(channel => _chatChannel.default.create(channel));
        model.incoming_chat_webhooks = model.incoming_chat_webhooks.map(webhook => {
          webhook.chat_channel = _chatChannel.default.create(webhook.chat_channel);
          return _object.default.create(webhook);
        });
        return model;
      } catch (err) {
        (0, _ajaxError.popupAjaxError)(err);
      }
    }
    titleToken() {
      return (0, _discourseI18n.i18n)("chat.incoming_webhooks.title");
    }
  }
  _exports.default = DiscourseChatIncomingWebhooksIndex;
});