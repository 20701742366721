define("discourse/plugins/docker_manager/discourse/templates/update", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <DPageHeader
    @titleLabel={{i18n "admin.docker.update_title"}}
    @descriptionLabel={{i18n "admin.docker.update_description"}}
    @shouldDisplay={{true}}
  >
    <:breadcrumbs>
      <DBreadcrumbsItem @path="/admin" @label={{i18n "admin_title"}} />
      <DBreadcrumbsItem
        @path="/admin/update"
        @label={{i18n "admin.docker.update_title"}}
      />
    </:breadcrumbs>
    <:tabs>
      <NavItem @route="update.index" @label="admin.docker.navigation.versions" />
      <NavItem
        @route="update.processes"
        @label="admin.docker.navigation.processes"
      />
    </:tabs>
  </DPageHeader>
  
  <div class="docker-manager admin-container">
    {{#if this.showBanner}}
      <div id="banner">
        <div id="banner-content">
          <div class="floated-buttons">
            <DButton
              @icon="xmark"
              @action={{this.dismiss}}
              @title="banner.close"
              class="btn btn-flat close"
            />
          </div>
  
          {{#each this.banner as |row|}}
            <p>{{html-safe row}}</p>
          {{/each}}
        </div>
      </div>
    {{/if}}
  
    {{outlet}}
  </div>
  */
  {
    "id": "uVwdBKPK",
    "block": "[[[8,[39,0],null,[[\"@titleLabel\",\"@descriptionLabel\",\"@shouldDisplay\"],[[28,[37,1],[\"admin.docker.update_title\"],null],[28,[37,1],[\"admin.docker.update_description\"],null],true]],[[\"breadcrumbs\",\"tabs\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@path\",\"@label\"],[\"/admin\",[28,[37,1],[\"admin_title\"],null]]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@path\",\"@label\"],[\"/admin/update\",[28,[37,1],[\"admin.docker.update_title\"],null]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],null,[[\"@route\",\"@label\"],[\"update.index\",\"admin.docker.navigation.versions\"]],null],[1,\"\\n    \"],[8,[39,5],null,[[\"@route\",\"@label\"],[\"update.processes\",\"admin.docker.navigation.processes\"]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"],[10,0],[14,0,\"docker-manager admin-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"showBanner\"]],[[[1,\"    \"],[10,0],[14,1,\"banner\"],[12],[1,\"\\n      \"],[10,0],[14,1,\"banner-content\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"floated-buttons\"],[12],[1,\"\\n          \"],[8,[39,8],[[24,0,\"btn btn-flat close\"]],[[\"@icon\",\"@action\",\"@title\"],[\"xmark\",[30,0,[\"dismiss\"]],\"banner.close\"]],null],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[42,[28,[37,10],[[28,[37,10],[[30,0,[\"banner\"]]],null]],null],null,[[[1,\"          \"],[10,2],[12],[1,[28,[35,12],[[30,1]],null]],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[46,[28,[37,14],null,null],null,null,null],[1,\"\\n\"],[13]],[\"row\"],false,[\"d-page-header\",\"i18n\",\":breadcrumbs\",\"d-breadcrumbs-item\",\":tabs\",\"nav-item\",\"div\",\"if\",\"d-button\",\"each\",\"-track-array\",\"p\",\"html-safe\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/docker_manager/discourse/templates/update.hbs",
    "isStrictMode": false
  });
});