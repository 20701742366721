define("discourse/plugins/discourse-ai/discourse/components/ai-forced-tool-strategy-selector", ["exports", "@ember/object", "discourse-i18n", "select-kit/components/combo-box"], function (_exports, _object, _discourseI18n, _comboBox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _comboBox.default.extend({
    content: (0, _object.computed)(function () {
      const content = [{
        id: -1,
        name: (0, _discourseI18n.i18n)("discourse_ai.ai_persona.tool_strategies.all")
      }];
      [1, 2, 5].forEach(i => {
        content.push({
          id: i,
          name: (0, _discourseI18n.i18n)("discourse_ai.ai_persona.tool_strategies.replies", {
            count: i
          })
        });
      });
      return content;
    }),
    selectKitOptions: {
      filterable: false
    }
  });
});