define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-embeddings", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscourseAiAiEmbeddingsRoute extends _discourse.default {
    model() {
      return this.store.findAll("ai-embedding");
    }
  }
  _exports.default = DiscourseAiAiEmbeddingsRoute;
});