define("discourse/plugins/discourse-ai/initializers/ai-chat-summarization", ["exports", "discourse/lib/api", "discourse/plugins/discourse-ai/discourse/components/modal/chat-modal-channel-summary"], function (_exports, _api, _chatModalChannelSummary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.34.0", api => {
    const siteSettings = api.container.lookup("service:site-settings");
    const currentUser = api.getCurrentUser();
    const chatService = api.container.lookup("service:chat");
    const modal = api.container.lookup("service:modal");
    const canSummarize = currentUser && currentUser.can_summarize;
    if (!siteSettings.chat_enabled || !chatService?.userCanChat || !canSummarize) {
      return;
    }
    api.registerChatComposerButton({
      translatedLabel: "discourse_ai.summarization.chat.title",
      id: "channel-summary",
      icon: "discourse-sparkles",
      position: "dropdown",
      action: () => {
        modal.show(_chatModalChannelSummary.default, {
          model: {
            channelId: chatService.activeChannel?.id
          }
        });
      }
    });
  });
});