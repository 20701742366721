define("discourse/plugins/discourse-presence/discourse/templates/connectors/topic-above-footer-buttons/presence", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <TopicPresenceDisplay @topic={{@outletArgs.model}} />
  */
  {
    "id": "+1wbySXZ",
    "block": "[[[8,[39,0],null,[[\"@topic\"],[[30,1,[\"model\"]]]],null]],[\"@outletArgs\"],false,[\"topic-presence-display\"]]",
    "moduleName": "discourse/plugins/discourse-presence/discourse/templates/connectors/topic-above-footer-buttons/presence.hbs",
    "isStrictMode": false
  });
});