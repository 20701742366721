define("discourse/plugins/discourse-ai/discourse/components/reviewable-ai-post", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="flagged-post-header">
    <ReviewableTopicLink @reviewable={{@reviewable}} @tagName="" />
    <ReviewablePostEdits @reviewable={{@reviewable}} @tagName="" />
  </div>
  
  <div class="post-contents-wrapper">
    <ReviewableCreatedBy @user={{@reviewable.target_created_by}} @tagName="" />
    <div class="post-contents">
      <ReviewablePostHeader
        @reviewable={{@reviewable}}
        @createdBy={{@reviewable.target_created_by}}
        @tagName=""
      />
      <div class="post-body">
        {{#if @reviewable.blank_post}}
          <p>{{i18n "review.deleted_post"}}</p>
        {{else}}
          {{html-safe @reviewable.cooked}}
        {{/if}}
      </div>
  
      {{yield}}
  
      <ModelAccuracies @accuracies={{@reviewable.payload.accuracies}} />
    </div>
  </div>
  */
  {
    "id": "Cs7KWxKV",
    "block": "[[[10,0],[14,0,\"flagged-post-header\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@reviewable\",\"@tagName\"],[[30,1],\"\"]],null],[1,\"\\n  \"],[8,[39,2],null,[[\"@reviewable\",\"@tagName\"],[[30,1],\"\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"post-contents-wrapper\"],[12],[1,\"\\n  \"],[8,[39,3],null,[[\"@user\",\"@tagName\"],[[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"post-contents\"],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@reviewable\",\"@createdBy\",\"@tagName\"],[[30,1],[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n    \"],[10,0],[14,0,\"post-body\"],[12],[1,\"\\n\"],[41,[30,1,[\"blank_post\"]],[[[1,\"        \"],[10,2],[12],[1,[28,[35,7],[\"review.deleted_post\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,8],[[30,1,[\"cooked\"]]],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[18,2,null],[1,\"\\n\\n    \"],[8,[39,10],null,[[\"@accuracies\"],[[30,1,[\"payload\",\"accuracies\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@reviewable\",\"&default\"],false,[\"div\",\"reviewable-topic-link\",\"reviewable-post-edits\",\"reviewable-created-by\",\"reviewable-post-header\",\"if\",\"p\",\"i18n\",\"html-safe\",\"yield\",\"model-accuracies\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/components/reviewable-ai-post.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)(undefined, "reviewable-ai-post"));
});