define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-llms-new", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseAiLlmsNew extends _discourse.default {
    queryParams = {
      llmTemplate: {
        refreshModel: true
      }
    };
    async model() {
      const record = this.store.createRecord("ai-llm");
      record.provider_params = {};
      return record;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set("allLlms", this.modelFor("adminPlugins.show.discourse-ai-llms"));
      controller.set("llmTemplate", this.paramsFor(this.routeName).llmTemplate || null);
    }
  }
  _exports.default = AdminPluginsShowDiscourseAiLlmsNew;
});