define("discourse/plugins/chat/discourse/components/styleguide/chat-thread-list-item", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/owner", "@ember/runloop", "@ember/service", "discourse/plugins/chat/discourse/lib/fabricators", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _owner, _runloop, _service, _fabricators, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <StyleguideExample @title="<Chat::ThreadList::Item>">
    <Styleguide::Component>
      {{#if this.thread}}
        <Chat::ThreadList::Item @thread={{this.thread}} />
      {{/if}}
    </Styleguide::Component>
  </StyleguideExample>
  */
  {
    "id": "ZyRtRtf/",
    "block": "[[[8,[39,0],null,[[\"@title\"],[\"<Chat::ThreadList::Item>\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"thread\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@thread\"],[[30,0,[\"thread\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"styleguide-example\",\"styleguide/component\",\"if\",\"chat/thread-list/item\"]]",
    "moduleName": "discourse/plugins/chat/discourse/components/styleguide/chat-thread-list-item.hbs",
    "isStrictMode": false
  });
  class ChatStyleguideChatThreadListItem extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "thread", [_tracking.tracked]))();
    #thread = (() => (dt7948.i(this, "thread"), void 0))();
    constructor() {
      super(...arguments);
      (0, _runloop.next)(() => {
        this.thread = new _fabricators.default((0, _owner.getOwner)(this)).thread();
      });
    }
  }
  _exports.default = ChatStyleguideChatThreadListItem;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ChatStyleguideChatThreadListItem);
});