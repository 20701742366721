define("discourse/plugins/discourse-ai/discourse/lib/ai-streamer/updaters/post-updater", ["exports", "discourse/lib/load-morphlex", "discourse/lib/text", "discourse/plugins/discourse-ai/discourse/lib/ai-streamer/progress-handlers", "discourse/plugins/discourse-ai/discourse/lib/ai-streamer/updaters/stream-updater"], function (_exports, _loadMorphlex, _text, _progressHandlers, _streamUpdater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class PostUpdater extends _streamUpdater.default {
    morphingOptions = {
      beforeAttributeUpdated: (element, attributeName) => {
        return !(element.tagName === "DETAILS" && attributeName === "open");
      }
    };
    constructor(postStream, postId) {
      super();
      this.postStream = postStream;
      this.postId = postId;
      this.post = postStream.findLoadedPost(postId);
      if (this.post) {
        this.postElement = document.querySelector(`#post_${this.post.post_number}`);
      }
    }
    get element() {
      return this.postElement;
    }
    set streaming(value) {
      if (this.postElement) {
        if (value) {
          this.postElement.classList.add("streaming");
        } else {
          this.postElement.classList.remove("streaming");
        }
      }
    }
    async setRaw(value, done) {
      this.post.set("raw", value);
      const cooked = await (0, _text.cook)(value);

      // resets animation
      this.element.classList.remove("streaming");
      void this.element.offsetWidth;
      this.element.classList.add("streaming");
      const cookedElement = document.createElement("div");
      cookedElement.innerHTML = cooked;
      if (!done) {
        (0, _progressHandlers.addProgressDot)(cookedElement);
      }
      await this.setCooked(cookedElement.innerHTML);
    }
    async setCooked(value) {
      this.post.set("cooked", value);
      (await (0, _loadMorphlex.default)()).morphInner(this.postElement.querySelector(".cooked"), `<div>${value}</div>`, this.morphingOptions);
    }
    get raw() {
      return this.post.get("raw") || "";
    }
  }
  _exports.default = PostUpdater;
});