define("discourse/plugins/automation/admin/templates/components/topic-trigger", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group">
    <label class="control-label">
      {{i18n "discourse_automation.triggerables.topic.topic_id.label"}}
    </label>
  
    <div class="controls">
      <Input
        @value={{this.metadata.topic_id}}
        {{on "input" (action (mut this.metadata.topic_id) value="target.value")}}
      />
    </div>
  </div>
  */
  {
    "id": "TRQYq+jN",
    "block": "[[[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"discourse_automation.triggerables.topic.topic_id.label\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,3],[[4,[38,4],[\"input\",[28,[37,5],[[30,0],[28,[37,6],[[30,0,[\"metadata\",\"topic_id\"]]],null]],[[\"value\"],[\"target.value\"]]]],null]],[[\"@value\"],[[30,0,[\"metadata\",\"topic_id\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"label\",\"i18n\",\"input\",\"on\",\"action\",\"mut\"]]",
    "moduleName": "discourse/plugins/automation/admin/templates/components/topic-trigger.hbs",
    "isStrictMode": false
  });
});