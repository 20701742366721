define("discourse/plugins/chat/discourse/components/chat-replying-indicator", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/did-update", "@ember/render-modifiers/modifiers/will-destroy", "@ember/service", "@ember/utils", "discourse/helpers/concat-class", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _object, _didInsert, _didUpdate, _willDestroy, _service, _utils, _concatClass, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatReplyingIndicator extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "presence", [_service.service]))();
    #presence = (() => (dt7948.i(this, "presence"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "presenceChannel", [_tracking.tracked], function () {
      return null;
    }))();
    #presenceChannel = (() => (dt7948.i(this, "presenceChannel"), void 0))();
    async updateSubscription() {
      await this.unsubscribe();
      await this.subscribe();
    }
    static #_4 = (() => dt7948.n(this.prototype, "updateSubscription", [_object.action]))();
    async subscribe() {
      this.presenceChannel = this.presence.getChannel(this.args.presenceChannelName);
      await this.presenceChannel.subscribe();
    }
    static #_5 = (() => dt7948.n(this.prototype, "subscribe", [_object.action]))();
    async unsubscribe() {
      if (this.presenceChannel?.subscribed) {
        await this.presenceChannel.unsubscribe();
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "unsubscribe", [_object.action]))();
    get users() {
      return this.presenceChannel?.get("users")?.filter(u => u.id !== this.currentUser.id) || [];
    }
    get usernames() {
      return this.users.mapBy("username");
    }
    get text() {
      if (this.usernames.length === 1) {
        return (0, _discourseI18n.i18n)("chat.replying_indicator.single_user", {
          username: this.usernames[0]
        });
      }
      if (this.usernames.length < 4) {
        const lastUsername = this.usernames[this.usernames.length - 1];
        const commaSeparatedUsernames = this.usernames.slice(0, this.usernames.length - 1).join((0, _discourseI18n.i18n)("word_connector.comma"));
        return (0, _discourseI18n.i18n)("chat.replying_indicator.multiple_users", {
          commaSeparatedUsernames,
          lastUsername
        });
      }
      const commaSeparatedUsernames = this.usernames.slice(0, 2).join((0, _discourseI18n.i18n)("word_connector.comma"));
      return (0, _discourseI18n.i18n)("chat.replying_indicator.many_users", {
        commaSeparatedUsernames,
        count: this.usernames.length - 2
      });
    }
    get shouldRender() {
      return (0, _utils.isPresent)(this.usernames);
    }
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if @presenceChannelName}}
          <div
            class={{concatClass
              "chat-replying-indicator"
              (if this.presenceChannel.subscribed "is-subscribed")
            }}
            {{didInsert this.subscribe}}
            {{didUpdate this.updateSubscription @presenceChannelName}}
            {{willDestroy this.unsubscribe}}
          >
            {{#if this.shouldRender}}
              <span class="chat-replying-indicator__text">{{this.text}}</span>
              <span class="chat-replying-indicator__wave">
                <span class="chat-replying-indicator__dot">.</span>
                <span class="chat-replying-indicator__dot">.</span>
                <span class="chat-replying-indicator__dot">.</span>
              </span>
            {{/if}}
          </div>
        {{/if}}
      
    */
    {
      "id": "mY3LAGZX",
      "block": "[[[1,\"\\n\"],[41,[30,1],[[[1,\"      \"],[11,0],[16,0,[28,[32,0],[\"chat-replying-indicator\",[52,[30,0,[\"presenceChannel\",\"subscribed\"]],\"is-subscribed\"]],null]],[4,[32,1],[[30,0,[\"subscribe\"]]],null],[4,[32,2],[[30,0,[\"updateSubscription\"]],[30,1]],null],[4,[32,3],[[30,0,[\"unsubscribe\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"          \"],[10,1],[14,0,\"chat-replying-indicator__text\"],[12],[1,[30,0,[\"text\"]]],[13],[1,\"\\n          \"],[10,1],[14,0,\"chat-replying-indicator__wave\"],[12],[1,\"\\n            \"],[10,1],[14,0,\"chat-replying-indicator__dot\"],[12],[1,\".\"],[13],[1,\"\\n            \"],[10,1],[14,0,\"chat-replying-indicator__dot\"],[12],[1,\".\"],[13],[1,\"\\n            \"],[10,1],[14,0,\"chat-replying-indicator__dot\"],[12],[1,\".\"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[\"@presenceChannelName\"],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-replying-indicator.js",
      "scope": () => [_concatClass.default, _didInsert.default, _didUpdate.default, _willDestroy.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ChatReplyingIndicator;
});