define("discourse/plugins/discourse-ai/discourse/routes/admin-plugins-show-discourse-ai-embeddings-new", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsShowDiscourseAiEmbeddingsNew extends _discourse.default {
    async model() {
      const record = this.store.createRecord("ai-embedding");
      record.provider_params = {};
      return record;
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set("allEmbeddings", this.modelFor("adminPlugins.show.discourse-ai-embeddings"));
    }
  }
  _exports.default = AdminPluginsShowDiscourseAiEmbeddingsNew;
});