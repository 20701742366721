define("discourse/plugins/chat/discourse/services/chat-audio-manager", ["exports", "@ember/service", "discourse/lib/environment", "discourse/lib/get-url"], function (_exports, _service, _environment, _getUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.CHAT_SOUNDS = void 0;
  const CHAT_SOUNDS = _exports.CHAT_SOUNDS = {
    bell: [{
      src: "/plugins/chat/audio/bell.mp3",
      type: "audio/mpeg"
    }],
    ding: [{
      src: "/plugins/chat/audio/ding.mp3",
      type: "audio/mpeg"
    }]
  };
  const DEFAULT_SOUND_NAME = "bell";
  const THROTTLE_TIME = 3000; // 3 seconds

  class ChatAudioManager extends _service.default {
    canPlay = true;
    async play(name) {
      if (this.canPlay) {
        await this.#tryPlay(name);
        this.canPlay = false;
        setTimeout(() => {
          this.canPlay = true;
        }, THROTTLE_TIME);
      }
    }
    async #tryPlay(name) {
      const src = (0, _getUrl.getURLWithCDN)((CHAT_SOUNDS[name] || CHAT_SOUNDS[DEFAULT_SOUND_NAME])[0].src);
      const audio = new Audio(src);
      try {
        await audio.play();
      } catch {
        if (!(0, _environment.isTesting)()) {
          // eslint-disable-next-line no-console
          console.info("[chat] User needs to interact with DOM before we can play notification sounds.");
        }
      }
    }
  }
  _exports.default = ChatAudioManager;
});