define("discourse/plugins/chat/discourse/templates/admin-plugins/show/discourse-chat-incoming-webhooks/new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-detail discourse-chat-incoming-webhooks">
    <BackButton
      @label="chat.incoming_webhooks.back"
      @route="adminPlugins.show.discourse-chat-incoming-webhooks.index"
      class="incoming-chat-webhooks-back"
    />
  
    <ChatIncomingWebhookEditForm @chatChannels={{this.model.chat_channels}} />
  </div>
  */
  {
    "id": "i/zIklN/",
    "block": "[[[10,0],[14,0,\"admin-detail discourse-chat-incoming-webhooks\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"incoming-chat-webhooks-back\"]],[[\"@label\",\"@route\"],[\"chat.incoming_webhooks.back\",\"adminPlugins.show.discourse-chat-incoming-webhooks.index\"]],null],[1,\"\\n\\n  \"],[8,[39,2],null,[[\"@chatChannels\"],[[30,0,[\"model\",\"chat_channels\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"div\",\"back-button\",\"chat-incoming-webhook-edit-form\"]]",
    "moduleName": "discourse/plugins/chat/discourse/templates/admin-plugins/show/discourse-chat-incoming-webhooks/new.hbs",
    "isStrictMode": false
  });
});