define("discourse/plugins/discourse-ai/discourse/components/modal/spam-test-modal", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/modifier", "@ember/object", "discourse/components/d-button", "discourse/components/d-modal", "discourse/helpers/with-event-value", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-i18n", "discourse/plugins/discourse-ai/discourse/components/ai-indicator-wave", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _modifier, _object, _dButton, _dModal, _withEventValue, _ajax, _ajaxError, _discourseI18n, _aiIndicatorWave, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SpamTestModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "testResult", [_tracking.tracked]))();
    #testResult = (() => (dt7948.i(this, "testResult"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "isLoading", [_tracking.tracked], function () {
      return false;
    }))();
    #isLoading = (() => (dt7948.i(this, "isLoading"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "postUrl", [_tracking.tracked], function () {
      return "";
    }))();
    #postUrl = (() => (dt7948.i(this, "postUrl"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "scanLog", [_tracking.tracked], function () {
      return "";
    }))();
    #scanLog = (() => (dt7948.i(this, "scanLog"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "isSpam", [_tracking.tracked]))();
    #isSpam = (() => (dt7948.i(this, "isSpam"), void 0))();
    async runTest() {
      this.isLoading = true;
      try {
        const response = await (0, _ajax.ajax)(`/admin/plugins/discourse-ai/ai-spam/test.json`, {
          type: "POST",
          data: {
            post_url: this.postUrl,
            custom_instructions: this.args.model.customInstructions,
            llm_id: this.args.model.llmId
          }
        });
        this.isSpam = response.is_spam;
        this.testResult = response.is_spam ? (0, _discourseI18n.i18n)("discourse_ai.spam.test_modal.spam") : (0, _discourseI18n.i18n)("discourse_ai.spam.test_modal.not_spam");
        this.scanLog = response.log;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.isLoading = false;
      }
    }
    static #_6 = (() => dt7948.n(this.prototype, "runTest", [_object.action]))();
    static #_7 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DModal
          @title={{i18n "discourse_ai.spam.test_modal.title"}}
          @closeModal={{@closeModal}}
          @bodyClass="spam-test-modal__body"
          class="spam-test-modal"
        >
          <:body>
            <div class="control-group">
              <label>{{i18n "discourse_ai.spam.test_modal.post_url_label"}}</label>
              <input
                {{on "input" (withEventValue (fn (mut this.postUrl)))}}
                type="text"
                placeholder={{i18n
                  "discourse_ai.spam.test_modal.post_url_placeholder"
                }}
              />
            </div>
    
            {{#if this.testResult}}
              <div class="spam-test-modal__test-result">
                <h3>{{i18n "discourse_ai.spam.test_modal.result"}}</h3>
                <div
                  class="spam-test-modal__verdict
                    {{if this.isSpam 'is-spam' 'not-spam'}}"
                >
                  {{this.testResult}}
                </div>
                {{#if this.scanLog}}
                  <div class="spam-test-modal__log">
                    <h4>{{i18n "discourse_ai.spam.test_modal.scan_log"}}</h4>
                    <pre>{{this.scanLog}}</pre>
                  </div>
                {{/if}}
              </div>
            {{/if}}
          </:body>
    
          <:footer>
            <DButton
              @action={{this.runTest}}
              @label="discourse_ai.spam.test_modal.run"
              @disabled={{this.isLoading}}
              class="btn-primary spam-test-modal__run-button"
            >
              <AiIndicatorWave @loading={{this.isLoading}} />
            </DButton>
          </:footer>
        </DModal>
      
    */
    {
      "id": "34+xPAxG",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"spam-test-modal\"]],[[\"@title\",\"@closeModal\",\"@bodyClass\"],[[28,[32,1],[\"discourse_ai.spam.test_modal.title\"],null],[30,1],\"spam-test-modal__body\"]],[[\"body\",\"footer\"],[[[[1,\"\\n        \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n          \"],[10,\"label\"],[12],[1,[28,[32,1],[\"discourse_ai.spam.test_modal.post_url_label\"],null]],[13],[1,\"\\n          \"],[11,\"input\"],[16,\"placeholder\",[28,[32,1],[\"discourse_ai.spam.test_modal.post_url_placeholder\"],null]],[24,4,\"text\"],[4,[32,2],[\"input\",[28,[32,3],[[28,[32,4],[[28,[31,0],[[30,0,[\"postUrl\"]]],null]],null]],null]],null],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"testResult\"]],[[[1,\"          \"],[10,0],[14,0,\"spam-test-modal__test-result\"],[12],[1,\"\\n            \"],[10,\"h3\"],[12],[1,[28,[32,1],[\"discourse_ai.spam.test_modal.result\"],null]],[13],[1,\"\\n            \"],[10,0],[15,0,[29,[\"spam-test-modal__verdict\\n                \",[52,[30,0,[\"isSpam\"]],\"is-spam\",\"not-spam\"]]]],[12],[1,\"\\n              \"],[1,[30,0,[\"testResult\"]]],[1,\"\\n            \"],[13],[1,\"\\n\"],[41,[30,0,[\"scanLog\"]],[[[1,\"              \"],[10,0],[14,0,\"spam-test-modal__log\"],[12],[1,\"\\n                \"],[10,\"h4\"],[12],[1,[28,[32,1],[\"discourse_ai.spam.test_modal.scan_log\"],null]],[13],[1,\"\\n                \"],[10,\"pre\"],[12],[1,[30,0,[\"scanLog\"]]],[13],[1,\"\\n              \"],[13],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"]],[]],[[[1,\"\\n        \"],[8,[32,5],[[24,0,\"btn-primary spam-test-modal__run-button\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"runTest\"]],\"discourse_ai.spam.test_modal.run\",[30,0,[\"isLoading\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,6],null,[[\"@loading\"],[[30,0,[\"isLoading\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n  \"]],[\"@closeModal\"],false,[\"mut\",\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/modal/spam-test-modal.js",
      "scope": () => [_dModal.default, _discourseI18n.i18n, _modifier.on, _withEventValue.default, _helper.fn, _dButton.default, _aiIndicatorWave.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SpamTestModal;
});