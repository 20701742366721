define("discourse/plugins/automation/admin/routes/admin-plugins-discourse-automation", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Automation extends _discourse.default {
    controllerName = "admin-plugins-discourse-automation";
  }
  _exports.default = Automation;
});