define("discourse/plugins/discourse-ai/discourse/connectors/after-d-editor/composer-open", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse-i18n"], function (_exports, _component, _object, _service, _discourseI18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get composerModel() {
      return this.args?.outletArgs?.composer;
    }
    get renderChatWarning() {
      return this.siteSettings.ai_bot_enable_chat_warning;
    }
    get aiBotClasses() {
      if (this.composerModel?.title === (0, _discourseI18n.i18n)("discourse_ai.ai_bot.default_pm_prefix")) {
        return "ai-bot-chat";
      } else {
        return "ai-bot-pm";
      }
    }
    static #_3 = (() => dt7948.n(this.prototype, "aiBotClasses", [(0, _object.computed)("composerModel.targetRecipients", "composerModel.title")]))();
    get isAiBotChat() {
      if (this.composerModel && this.composerModel.targetRecipients && this.currentUser.ai_enabled_chat_bots) {
        let recipients = this.composerModel.targetRecipients.split(",");
        return this.currentUser.ai_enabled_chat_bots.any(bot => recipients.any(username => username === bot.username));
      }
      return false;
    }
    static #_4 = (() => dt7948.n(this.prototype, "isAiBotChat", [(0, _object.computed)("composerModel.targetRecipients")]))();
  }
  _exports.default = _default;
});