define("discourse/plugins/chat/discourse/components/chat/routes/channel-threads", ["exports", "discourse/plugins/chat/discourse/components/chat/thread-list/header", "discourse/plugins/chat/discourse/components/chat-thread-list", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _header, _chatThreadList, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatRoutesChannelThreads = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="c-routes --channel-threads">
      <ChatThreadListHeader @channel={{@channel}} />
      <ChatThreadList @channel={{@channel}} @includeHeader={{true}} />
    </div>
  
  */
  {
    "id": "vbnP5grD",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"c-routes --channel-threads\"],[12],[1,\"\\n    \"],[8,[32,0],null,[[\"@channel\"],[[30,1]]],null],[1,\"\\n    \"],[8,[32,1],null,[[\"@channel\",\"@includeHeader\"],[[30,1],true]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@channel\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/routes/channel-threads.js",
    "scope": () => [_header.default, _chatThreadList.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "channel-threads:ChatRoutesChannelThreads"));
  var _default = _exports.default = ChatRoutesChannelThreads;
});