define("discourse/plugins/chat/discourse/services/interacted-chat-message", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class InteractedChatMessage extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "secondaryOptionsOpen", [_tracking.tracked], function () {
      return false;
    }))();
    #secondaryOptionsOpen = (() => (dt7948.i(this, "secondaryOptionsOpen"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "emojiPickerOpen", [_tracking.tracked], function () {
      return false;
    }))();
    #emojiPickerOpen = (() => (dt7948.i(this, "emojiPickerOpen"), void 0))();
  }
  _exports.default = InteractedChatMessage;
});