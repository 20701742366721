define("discourse/plugins/discourse-ai/discourse/components/post-menu/ai-cancel-streaming-button", ["exports", "@glimmer/component", "@ember/object", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _dButton, _ajax, _ajaxError, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiCancelStreamingButton extends _component.default {
    // TODO (glimmer-post-menu): Remove this static function and move the code into the button action after the widget code is removed
    static async cancelStreaming(post) {
      try {
        await (0, _ajax.ajax)(`/discourse-ai/ai-bot/post/${post.id}/stop-streaming`, {
          type: "POST"
        });
        document.querySelector(`#post_${post.post_number}`).classList.remove("streaming");
      } catch (e) {
        (0, _ajaxError.popupAjaxError)(e);
      }
    }
    cancelStreaming() {
      this.constructor.cancelStreaming(this.args.post);
    }
    static #_ = (() => dt7948.n(this.prototype, "cancelStreaming", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="post-action-menu__ai-cancel-streaming cancel-streaming"
          ...attributes
          @action={{this.cancelStreaming}}
          @icon="pause"
          @title="discourse_ai.ai_bot.cancel_streaming"
        />
      
    */
    {
      "id": "eS4Xq4iv",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"post-action-menu__ai-cancel-streaming cancel-streaming\"],[17,1]],[[\"@action\",\"@icon\",\"@title\"],[[30,0,[\"cancelStreaming\"]],\"pause\",\"discourse_ai.ai_bot.cancel_streaming\"]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/components/post-menu/ai-cancel-streaming-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiCancelStreamingButton;
});