define("discourse/plugins/discourse-ai/discourse/controllers/preferences-ai", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax-error", "discourse-common/config/environment"], function (_exports, _tracking, _controller, _object, _service, _ajaxError, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const AI_ATTRS = ["auto_image_caption"];
  class PreferencesAiController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "saved", [_tracking.tracked], function () {
      return false;
    }))();
    #saved = (() => (dt7948.i(this, "saved"), void 0))();
    get showAutoImageCaptionSetting() {
      const aiHelperEnabledFeatures = this.siteSettings.ai_helper_enabled_features.split("|");
      return this.model?.user_allowed_ai_auto_image_captions && aiHelperEnabledFeatures.includes("image_caption") && this.siteSettings.ai_helper_enabled;
    }
    save() {
      this.saved = false;
      return this.model.save(AI_ATTRS).then(() => {
        this.saved = true;
        if (!(0, _environment.isTesting)()) {
          location.reload();
        }
      }).catch(_ajaxError.popupAjaxError);
    }
    static #_3 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
  }
  _exports.default = PreferencesAiController;
});