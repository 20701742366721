define("discourse/plugins/discourse-ai/discourse/services/gists", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Gists extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "router", [_service.service]))();
    #router = (() => (dt7948.i(this, "router"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "preference", [_tracking.tracked], function () {
      return localStorage.getItem("topicListLayout");
    }))();
    #preference = (() => (dt7948.i(this, "preference"), void 0))();
    get shouldShow() {
      return this.router.currentRoute.attributes?.list?.topics?.some(topic => topic.ai_topic_gist);
    }
    setPreference(value) {
      this.preference = value;
      localStorage.setItem("topicListLayout", value);
      if (this.preference === "table-ai") {
        localStorage.setItem("aiPreferred", true);
      }
    }
  }
  _exports.default = Gists;
});