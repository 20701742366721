define("discourse/plugins/chat/discourse/services/chat-drafts-manager", ["exports", "@ember/runloop", "@ember/service"], function (_exports, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ChatDraftsManager extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "chatApi", [_service.service]))();
    #chatApi = (() => (dt7948.i(this, "chatApi"), void 0))();
    drafts = {};
    willDestroy() {
      super.willDestroy(...arguments);
      (0, _runloop.cancel)(this._persistHandler);
    }
    async add(message, channelId, threadId) {
      try {
        this.drafts[this.key(channelId, threadId)] = message;
        await this.persistDraft(message, channelId, threadId);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("Couldn't save draft", e);
      }
    }
    get(channelId, threadId) {
      return this.drafts[this.key(channelId, threadId)];
    }
    remove(channelId, threadId) {
      delete this.drafts[this.key(channelId, threadId)];
    }
    reset() {
      this.drafts = {};
    }
    key(channelId, threadId) {
      let key = `c-${channelId}`;
      if (threadId) {
        key += `:t-${threadId}`;
      }
      return key.toString();
    }
    async persistDraft(message, channelId, threadId) {
      try {
        await this.chatApi.saveDraft(channelId, message.toJSONDraft(), {
          threadId
        });
        message.draftSaved = true;
      } catch {
        // We don't want to throw an error if the draft fails to save
      }
    }
  }
  _exports.default = ChatDraftsManager;
});