define("discourse/plugins/discourse-ai/discourse/templates/admin-plugins/show/discourse-ai-tools/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <section class="ai-persona-tool-editor__current admin-detail pull-left">
    <AiToolEditor
      @tools={{this.allTools}}
      @model={{this.model}}
      @presets={{this.presets}}
    />
  </section>
  */
  {
    "id": "wRciesRW",
    "block": "[[[10,\"section\"],[14,0,\"ai-persona-tool-editor__current admin-detail pull-left\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@tools\",\"@model\",\"@presets\"],[[30,0,[\"allTools\"]],[30,0,[\"model\"]],[30,0,[\"presets\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"section\",\"ai-tool-editor\"]]",
    "moduleName": "discourse/plugins/discourse-ai/discourse/templates/admin-plugins/show/discourse-ai-tools/edit.hbs",
    "isStrictMode": false
  });
});